import http from "@/shared/services/http.js";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import {
  CUSTOMER_RESOURCE_INFO,
  GET_ALL_JOB_PARAMS,
  GET_JOB_DEFINITION_PARAMS,
  RUN_CASE_API_PARAMS,
  SCHEDULE_TYPE,
} from "@/shared/interface/newItem";
import { actionsEnum, Auth, subjectsEnum } from "../auth";

export const tsService = {
  /**
   * get the object contains all projects and jobs of a product
   * @param {GET_ALL_JOB_PARAMS} params: request params
   * @returns Promise
   */
  getAllJobs(params: GET_ALL_JOB_PARAMS): Promise<any> {
    const currentApp = localStorage.getItem("currentApp") || "{}";
    const authParams = Auth.addAuthParamstoRequest(
      actionsEnum.RUN,
      subjectsEnum.APPLICATION
    );
    return http.get(
      process.env.VUE_APP_BASE_WS_URL +
        `/cionb/case/contentList?app=${
          JSON.parse(currentApp).name
        }&product=${params.rundeckName.toString()}`,
      { params: JSON.stringify(authParams) }
    );
  },
  /**
   * get job definition
   * @param {GET_JOB_DEFINITION_PARAMS} params: request params
   * @returns Promise
   */
  getJobDefinition(params: GET_JOB_DEFINITION_PARAMS): Promise<any> {
    const url =
      process.env.VUE_APP_BASE_WS_URL +
      "/cionb/case/queryParameters?runDeckName=" +
      encodeURIComponent(params.runDeckName) +
      "&product=" +
      encodeURIComponent(params.product) +
      "&id=" +
      encodeURIComponent(params.id) +
      "&jobName=" +
      encodeURIComponent(params.jobName) +
      "&runDeckId=" +
      encodeURIComponent(params.jobRunDeckId);
    return http.get(url);
  },

  /**
   * get all db of ccv2, used by commerce-specific option
   * @param {string} tname: tanent name: use "*" to get all the subscriptions
   * @param {string} sid: subscription id, get all servers of the subscription
   * @param {string} sname: server name, get all the database of the server
   * @returns Observable
   */
  getCCV2AllDBs(tname: string, sid: string, sname: string): any {
    const httpOptions: any = {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIM_KEY,
      },
    };
    let queryParam = "";
    let valueStr = "";
    let labelStr = "";
    if (tname) {
      queryParam = "tname=*";
      valueStr = "SubscriptionId";
      labelStr = "SubscriptionName";
    } else if (sid) {
      queryParam = "sid=" + sid;
      valueStr = labelStr = "Servername";
    } else if (sname) {
      queryParam = "sname=" + sname;
      valueStr = labelStr = "DatabaseName";
    }
    const getPromise = http.get<any>(
      process.env.VUE_APP_CIS_BASE_URL + "cosmos/CCv2DB?" + queryParam,
      httpOptions
    );
    return from(getPromise).pipe(
      map((data: any) => {
        let newData = [];
        if (data.data) {
          const allData = data.data;
          newData = allData.map((o) => {
            return {
              ...o,
              value: o[valueStr],
              label: o[labelStr],
              leaf: !!sname,
            };
          });
        }
        return newData;
      })
    );
  },

  /**
   * get all db of ccv2, used by commerce-specific option
   * @param {string} tname: tanent name: use "*" to get all the subscriptions
   * @param {string} sid: subscription id, get all servers of the subscription
   * @param {string} sname: server name, get all the database of the server
   * @returns Observable
   */
  getCCV2AllDBMinimum(): any {
    const httpOptions: any = {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIM_KEY,
      },
    };
    const getPromise = http.get<any>(
      process.env.VUE_APP_CIS_BASE_URL + "cosmos/CCv2DB",
      httpOptions
    );
    return from(getPromise).pipe(
      map((data: any) => {
        let newData = [];
        if (data.data) {
          const dbs = data.data.filter(
            (o) => o.DatabaseName && o.DatabaseName.toLowerCase() !== "master"
          );
          newData = dbs.map((o) => {
            return o.DatabaseName;
          });
        }
        return newData;
      })
    );
  },

  /**
   * Execute job
   * trigger by clicking the save button of creating new work item
   * @param {RUN_CASE_API_PARAMS} params: request params
   * @returns Promise
   */
  runCase(params: RUN_CASE_API_PARAMS): Promise<any> {
    const authParams = Auth.addAuthParamstoRequest(
      actionsEnum.RUN,
      subjectsEnum.APPLICATION
    );
    if (params.scheduleType === SCHEDULE_TYPE.periodically) {
      const submitObject = {
        workItemId: params.id,
      };
      return http.post(
        process.env.VUE_APP_BASE_WS_URL + "/cionb/case/scheduler/periodic",
        submitObject
      );
    } else {
      return http.get(
        process.env.VUE_APP_BASE_WS_URL +
          `/cionb/case/run/case/${params.id}/${params.name}`,
        { params: JSON.stringify(authParams) }
      );
    }
  },

  /**
   *
   * @param {string} url: the remote url to get option values
   * @returns Promise
   */
  getOptionValueByURL(url: string): Promise<any> {
    return http.get(url);
  },

  /**
   *
   * @param {string} queryValue: the search str to get the customer resources
   * @returns Promise
   */
  getCustomerResource(
    queryValue: string,
    pageOffset: number,
    resourceType?: string
  ): Promise<CUSTOMER_RESOURCE_INFO[]> {
    const pageSize = 25;
    return http.get(
      process.env.VUE_APP_BASE_MD_URL +
        `commerce/resources?searchItem=${queryValue}&offset=${pageSize}&pageNo=${pageOffset}&resourceTypes=${resourceType}`
    );
  },

  /**
   * download the execute report
   * @param {string} executor: the excutor of current content
   * @param {string} filePath: the path of report in azure storage
   * @returns Promise
   */
  downloadExecuteFile(executor: string, filePath: string): Promise<any> {
    const Header = {
      responseType: "blob",
    };
    return http.get(
      process.env.VUE_APP_BASE_WS_URL +
        "/cionb/case/download/files?executor=" +
        executor +
        "&filePath=" +
        filePath,
      Header
    );
  },

  getFileSizeArrforFilepaths(filePaths: string[]): Promise<any> {
    const filePathStr = filePaths.join(";");
    return http.get(
      process.env.VUE_APP_BASE_WS_URL +
        "/cionb/case/query/fileSize?filePath=" +
        filePathStr
    );
  },

  /**
   * abort running jobs
   * @param {string} executionId: execution id which used to identify running job
   * @returns Promise
   */
  cancelRunningJob(executionId: string): Promise<any> {
    const authParams = Auth.addAuthParamstoRequest(
      actionsEnum.RUN,
      subjectsEnum.APPLICATION
    );
    return http.get(
      process.env.VUE_APP_BASE_WS_URL + `/cionb/case/cancel/${executionId}`,
      { params: JSON.stringify(authParams) }
    );
  },
};
